import React, { Component } from "react";
import {
  View,
  Image,
  Text,
  TouchableOpacity,
  StatusBar,
} from "react-native-web";
import localforage from "localforage";
import NavigationWithFlex from "../components/NavigationWithFlex";
import InfiniteScroll from "react-infinite-scroll-component";
import { ApolloConsumer, Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import { handleError } from "../libs/errors";
import HeaderBar from "../components/HeaderBar";
import { withI18n, translate } from "../libs/withI18n";

import BackgroundImage from "../components/BackgroundImage";
import Article from "../components/ActivityItems/Article";
import StudentSavingTransaction from "../components/ActivityItems/StudentSavingTransaction";
import EmployeeSavingTransaction from "../components/ActivityItems/EmployeeSavingTransaction";
import SchoolAgenda from "../components/ActivityItems/SchoolAgenda";
import StudentBillPayment from "../components/ActivityItems/StudentBillPayment";
import StudentAttendanceRecord from "../components/ActivityItems/StudentAttendanceRecord";
import EmployeeAttendanceRecord from "../components/ActivityItems/EmployeeAttendanceRecord";
import BroadcastMessage from "../components/ActivityItems/BroadcastMessage";
import AcademicScore from "../components/ActivityItems/AcademicScore";
import PrimaryExtracurricularScore from "../components/ActivityItems/PrimaryExtracurricularScore";
import StudentViolation from "../components/ActivityItems/StudentViolation";
import Classwork from "../components/ActivityItems/Classwork";
import ClassworkExtra from "../components/ActivityItems/ClassworkExtra";
import SchoolPayBills from "../components/ActivityItems/SchoolPayBills";
import SubjectVideoMeeting from "../components/ActivityItems/SubjectVideoMeeting";
import BookBorrowingTransaction from "../components/ActivityItems/BookBorrowingTransaction";
import BookReturnedTransactionStudent from "../components/ActivityItems/BookReturnedTransactionStudent";
import BookBorrowingTransactionEmployee from "../components/ActivityItems/BookBorrowingTransactionEmployee";
import BookReturnedTransactionEmployee from "../components/ActivityItems/BookReturnedTransactionEmployee";
import CommBook from "../components/ActivityItems/CommBook";
import StudentAppreciation from "../components/ActivityItems/StudentAppreciation";
import { FormModal } from "../components/Modal";

import queryString from "query-string";
import account from "./account";

// import App, {
//   addNotification,
//   showLoadingSpinner,
//   hideLoadingSpinner
// } from "../App";

const ACTIVITY_LIMIT_PER_REQUEST = 10;
// const NEW_ACTIVITY_CHECK_INTERVAL =
//   process.env.NODE_ENV !== "production" ? 2 * 1000 : 5 * 60 * 1000;

class Activity extends Component {
  state = {
    username: "",
    recentActivityItems: [],
    hasMore: true,
    newestActivityItem: null,
    isLoading: true,
    session: {},

    broacastFilterObject: {
      visible: false,
      type: "",
      selectedType: "",
    },
  };

  BROADCAST_TYPE = [
    {
      type: "BroadcastMessage",
      name: "Broadcast",
    },
    {
      type: "Article",
      name: "Artikel",
    },
    // {
    //   type: "BookBorrowingTransaction",
    //   name: "Perpustakaan",
    // },
    {
      type: "StudentSavingTransaction",
      name: "Tabungan Siswa",
    },
    {
      type: "EmployeeSavingTransaction",
      name: "Tabungan Pegawai",
    },
    {
      type: "StudentAttendanceRecord",
      name: "Presensi Siswa",
    },
    {
      type: "EmployeeAttendanceRecord",
      name: "Presensi Pegawai",
    },
    {
      type: "SchoolAgenda",
      name: "Agenda Sekolah",
    },
    {
      type: "StudentBillAndPayment",
      name: "Pembayaran Siswa",
    },
    {
      type: "AcademicScore",
      name: "Nilai Akademik",
    },
    {
      type: "PrimaryExtracurricularScore",
      name: "Nilai Ekstrakurikuler",
    },
    {
      type: "StudentViolation",
      name: "Pelanggaran Siswa",
    },
    {
      type: "StudentAppreciation",
      name: "Appresiasi Siswa",
    },
    {
      type: "Classwork",
      name: "Tugas Akademik Siswa",
    },
    {
      type: "Feed",
      name: "Feed Akademik",
    },
    {
      type: "ClassworkExtra",
      name: "Tugas Ekstrakurikuler Siswa",
    },
    {
      type: "FeedExtra",
      name: "Feed Ekstrakurikuler",
    },
    {
      type: "SchoolPayBills",
      name: "School Pay",
    },
  ];

  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );
    accountSession = JSON.parse(accountSession);
    if (accountSession !== null) {
      this.setState({
        session: accountSession,
        username: accountSession.Account.name,
      });
      // this.props.history.push({
      //   pathname: "/activity",
      //   search: `?sessionId=${accountSession._id}`
      // });
    } else {
      this.props.history.push({
        pathname: "/login",
      });
    }

    this.fetchRecentActivityItems();

    // this.newActivityChecker = setInterval(async () => {
    //   if (this.state.newestActivityItem) {
    //     return;
    //   }
    //   try {
    //     let accountSession = await localforage.getItem(
    //       "accountSession",
    //       (err, val) => {
    //         if (err !== null) {
    //           return null;
    //         }
    //         return val;
    //       }
    //     );
    //     accountSession = JSON.parse(accountSession);
    //     var result = await this.props.client.query({
    //       query: NEWEST,
    //       variables: {
    //         sessionId: accountSession._id
    //       },
    //       fetchPolicy: "network-only"
    //     });
    //     const newestActivityItem = result.data.newestActivityItemBySessionId;
    //     if (newestActivityItem) {
    //       if (
    //         this.state.recentActivityItems.length === 0 ||
    //         this.state.recentActivityItems[0]._id !== newestActivityItem._id
    //       ) {
    //         this.setState({
    //           newestActivityItem
    //         });
    //         addNotification({
    //           title: "New activity for you!",
    //           message: "Pull down to refresh.",
    //           level: "default",
    //           // insert: "top",
    //           // container: "top-right",
    //           dismiss: { duration: 10 * 1000 }
    //         });
    //       }
    //     }
    //   } catch (err) {
    //     console.warn("newActivityChecker error:", err);
    //   }
    // }, NEW_ACTIVITY_CHECK_INTERVAL);
  };

  fetchRecentActivityItems = async (timeOffset, limit) => {
    // showLoadingSpinner();
    try {
      if (!timeOffset) {
        timeOffset = new Date().getTime();
      }
      if (!limit) {
        limit = ACTIVITY_LIMIT_PER_REQUEST;
      }
      let accountSession = await localforage.getItem(
        "accountSession",
        (err, val) => {
          if (err !== null) {
            return null;
          }
          return val;
        }
      );
      accountSession = JSON.parse(accountSession);
      var result = await this.props.client.query({
        query: RECENT,
        variables: {
          sessionId: accountSession._id,
          timeOffset,
          limit,
          type: this.state.broacastFilterObject.selectedType
            ? this.state.broacastFilterObject.selectedType
            : "",
        },
        fetchPolicy: "network-only",
      });
      let recentActivityItems = result.data.recentActivityItemsBySessionId;

      const hasMore =
        this.state.recentActivityItems.length < recentActivityItems.length;
      // console.log("fetchRecentActivityItems", {
      //   recentActivityItems,
      //   hasMore
      // });

      this.setState({
        recentActivityItems,
        hasMore,
      });
    } catch (err) {
      handleError(err, this.props);
    }
    this.setState({
      isLoading: false,
    });
    // hideLoadingSpinner();
  };

  refresh = () => {
    // this.setState({
    //   newestActivityItem: null
    // });
    // clearNotifications();
    // this.fetchRecentActivityItems();
    window.location.reload();
  };

  fetchMoreData = async () => {
    this.setState({
      loading: true,
    });
    try {
      const timeOffset =
        this.state.recentActivityItems &&
        this.state.recentActivityItems.length > 0
          ? new Date(
              this.state.recentActivityItems[
                this.state.recentActivityItems.length - 1
              ]._createdAt
            ).getTime()
          : new Date().getTime();
      const limit = ACTIVITY_LIMIT_PER_REQUEST;
      let accountSession = await localforage.getItem(
        "accountSession",
        (err, val) => {
          if (err !== null) {
            return null;
          }
          return val;
        }
      );
      accountSession = JSON.parse(accountSession);
      var result = await this.props.client.query({
        query: RECENT,
        variables: {
          sessionId: accountSession._id,
          timeOffset,
          limit,
          type: this.state.broacastFilterObject.selectedType
            ? this.state.broacastFilterObject.selectedType
            : "",
        },
      });
      const recentActivityItems = [
        ...this.state.recentActivityItems,
        ...result.data.recentActivityItemsBySessionId,
      ];
      const hasMore =
        this.state.recentActivityItems.length < recentActivityItems.length;
      // console.log("fetchMoreData", {
      //   recentActivityItems,
      //   hasMore
      // });
      this.setState({
        recentActivityItems,
        hasMore,
      });
    } catch (err) {
      handleError(err);
    }
    this.setState({
      loading: false,
    });
  };

  listenToActivityItemPress = async (data) => {
    // if (window.ReactNativeWebView) {
    //   window.ReactNativeWebView.postMessage(JSON.stringify(data));
    // }
    try {
      let accountSession = await localforage.getItem(
        "accountSession",
        (err, val) => {
          if (err !== null) {
            return null;
          }
          return val;
        }
      );
      accountSession = JSON.parse(accountSession);
      await this.props.interactWithActivityItem({
        variables: {
          activityItemId: data._id,
          type: "ACTIVITY_ITEM_CLICKED",
          sessionId: accountSession._id,
        },
      });
      if (data.activityType === "Classwork") {
        if (data.Student && data.Classwork) {
          if (!data.Classwork.teacherId) {
            handleError("Teacher Id Not Defined");
          }
          const queries = {
            sessionId: accountSession._id,
            studentId: data.Student._id,
            classroomId: data.Classwork.classroomId,
            PREFIX: data.Classwork.PREFIX,
            academicYear: data.Classwork.academicYear,
            semester: data.Classwork.semester,
            assessmentId: data.Classwork._id,
            teacherId: data.Classwork.teacherId
              ? data.Classwork.teacherId
              : "-",
          };

          let url = queryString.stringify(queries);

          this.props.history.replace({
            pathname: "/classwork",
            search: `?${url}`,
          });
        }
      } else if (data.activityType === "Feed") {
        const queries = {
          sessionId: accountSession._id,
          studentId: data.Student._id,
          classroomId: data.Classwork.classroomId,
          subjectId: data.Classwork.subjectId,
          PREFIX: data.Classwork.PREFIX,
          academicYear: data.Classwork.academicYear,
          semester: data.Classwork.semester,
        };

        // console.log(data)
        let url = queryString.stringify(queries);
        this.props.history.replace({
          pathname: "/papan_kelas",
          search: `?${url}`,
        });
      } else if (data.activityType === "ClassworkExtra") {
        if (!data.ClassworkExtra.teacherId) {
          handleError("Teacher Id Not Defined");
        }

        if (data.ClassworkExtra.classroomId) {
          const queries = {
            sessionId: accountSession._id,
            studentId: data.Student._id,
            classroomId: data.ClassworkExtra.classroomId,
            PREFIX: data.ClassworkExtra.PREFIX,
            academicYear: data.ClassworkExtra.academicYear,
            semester: data.ClassworkExtra.semester,
            assessmentId: data.ClassworkExtra._id,
            extracurricularId: data.ClassworkExtra.extracurricularId,
            teacherId: data.ClassworkExtra.teacherId
              ? data.ClassworkExtra.teacherId
              : "-",
          };

          let url = queryString.stringify(queries);
          // console.log(url)
          this.props.history.replace({
            pathname: "/classwork_ekstra",
            search: `?${url}`,
          });
        }
      } else if (data.activityType === "FeedExtra") {
        if (!data.ClassworkExtra.teacherId) {
          handleError("Teacher Id Not Defined");
        }

        if (data.ClassworkExtra.classroomId) {
          const queries = {
            sessionId: accountSession._id,
            studentId: data.Student._id,
            classroomId: data.ClassworkExtra.classroomId,
            extracurricularId: data.ClassworkExtra.extracurricularId,
            PREFIX: data.ClassworkExtra.PREFIX,
            academicYear: data.ClassworkExtra.academicYear,
            semester: data.ClassworkExtra.semester,
          };

          let url = queryString.stringify(queries);
          this.props.history.replace({
            pathname: "/daftar_feed_ekstra",
            search: `?${url}`,
          });
        }
      } else if (data.SubjectVideoMeeting) {
        const queries = {
          sessionId: accountSession._id,
          studentId: data.Student._id,
          classroomId: data.SubjectVideoMeeting.classroomId,
          PREFIX: data.SubjectVideoMeeting.PREFIX,
          academicYear: data.SubjectVideoMeeting.academicYear,
          semester: data.SubjectVideoMeeting.semester,
        };

        let url = queryString.stringify(queries);
        this.props.history.replace({
          pathname: "/subject_video_meeting",
          search: `?${url}`,
        });
      } else if (data.activityType === "AcademicScore") {
        const queries = {
          sessionId: accountSession._id,
          studentId: data.Student._id,
          PREFIX: data.AcademicScore.PREFIX,
          academicYear: data.AcademicScore.academicYear,
          semester: data.AcademicScore.semester,
          subjectName: data.AcademicScore.subjectName,
          subjectId: data.AcademicScore.subjectId,
          openFromNotif: true,
        };

        let url = queryString.stringify(queries);

        this.props.history.replace({
          pathname: "/academic_performance",
          search: `?${url}`,
        });
      } else if (data.activityType === "PrimaryExtracurricularScore") {
        console.log(data);
        const queries = {
          sessionId: accountSession._id,
          studentId: data.Student._id,
          PREFIX: data.PrimaryExtracurricularScore.PREFIX,
          academicYear: data.PrimaryExtracurricularScore.academicYear,
          semester: data.PrimaryExtracurricularScore.semester,
          extracurricularName:
            data.PrimaryExtracurricularScore.extracurricularName,
          extracurricularId: data.PrimaryExtracurricularScore.extracurricularId,
          openFromNotif: true,
        };

        let url = queryString.stringify(queries);
        this.props.history.replace({
          pathname: "/extracurricular_performance",
          search: `?${url}`,
        });
      } else if (data.activityType === "CommBook") {
        const queries = {
          sessionId: accountSession._id,
          studentId: data.studentId,
          PREFIX: data.PREFIX,
        };

        let url = queryString.stringify(queries);
        this.props.history.replace({
          pathname: "/comm_book",
          search: `?${url}`,
        });
      } else if (data.activityType === "StudentAttendanceRecord") {
        const queries = {
          sessionId: accountSession._id,
          studentId: data.studentId,
          month: data.StudentAttendanceRecord.month,
          year: data.StudentAttendanceRecord.year,
        };

        let url = queryString.stringify(queries);
        this.props.history.replace({
          pathname: "/presensi_siswa",
          search: `?${url}`,
        });
      } else if (data.activityType === "EmployeeAttendanceRecord") {
        const queries = {
          sessionId: accountSession._id,
          employeeId: data.employeeId,
          month: data.EmployeeAttendanceRecord.month,
          year: data.EmployeeAttendanceRecord.year,
        };

        let url = queryString.stringify(queries);
        this.props.history.replace({
          pathname: "/presensi_pegawai",
          search: `?${url}`,
        });
      }
    } catch (err) {
      console.warn("interactWithActivityItem error", err);
    }
  };

  renderActivityItem = (item) => {
    if (item.type === "Article") {
      if (!item.Article) {
        return <div></div>;
      }
      return (
        <Article
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "StudentSavingTransaction") {
      return (
        <StudentSavingTransaction
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "EmployeeSavingTransaction") {
      return (
        <EmployeeSavingTransaction
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "SchoolAgenda") {
      return (
        <SchoolAgenda
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "StudentBillPayment") {
      return (
        <StudentBillPayment
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "StudentAttendanceRecord") {
      return (
        <StudentAttendanceRecord
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "EmployeeAttendanceRecord") {
      return (
        <EmployeeAttendanceRecord
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "BroadcastMessage") {
      return (
        <BroadcastMessage
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "AcademicScore") {
      return (
        <AcademicScore
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "PrimaryExtracurricularScore") {
      return (
        <PrimaryExtracurricularScore
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "StudentViolation") {
      return (
        <StudentViolation
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "StudentAppreciation") {
      return (
        <StudentAppreciation
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "Classwork" || item.type === "Feed") {
      // console.log(item);
      return (
        <Classwork
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "SchoolPayBills") {
      // console.log(item);
      return (
        <SchoolPayBills
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "ClassworkExtra" || item.type === "FeedExtra") {
      // console.log(item);
      return (
        <ClassworkExtra
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "SubjectVideoMeeting") {
      return (
        <SubjectVideoMeeting
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "BookBorrowingTransaction") {
      return (
        <BookBorrowingTransaction
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "BookReturned") {
      return (
        <BookReturnedTransactionStudent
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "BookBorrowingEmployeeTransaction") {
      return (
        <BookBorrowingTransactionEmployee
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "BookReturnedEmployee") {
      return (
        <BookReturnedTransactionEmployee
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "CommBook") {
      return (
        <CommBook
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    } else if (item.type === "Announcement") {
      return (
        <StudentAppreciation
          key={item._id}
          {...item}
          onPress={this.listenToActivityItemPress}
        />
      );
    }
  };

  // listenScrollToTop = e => {
  //   if (
  //     e &&
  //     e.target &&
  //     e.target.scrollingElement &&
  //     e.target.scrollingElement.scrollTop === 0
  //   ) {
  //     console.log("Scroll to top!");
  //     if (window.ReactNativeWebView) {
  //       window.ReactNativeWebView.postMessage(
  //         JSON.stringify({
  //           type: "SCROLL_REACT_TOP"
  //         })
  //       );
  //     }
  //   }
  // };

  openFilter = () => {
    // console.log("Clicked");
    // if (e) e.preventDefault();
    this.setState({
      broacastFilterObject: {
        visible: true,
        type: this.state.broacastFilterObject.selectedType,
      },
    });
  };

  closeFilter = (e) => {
    if (e) e.preventDefault();
    this.setState({
      broacastFilterObject: {
        ...this.state.broacastFilterObject,
        visible: false,
      },
    });
  };

  submitFilter = (e) => {
    if (e) e.preventDefault();
    this.setState({
      broacastFilterObject: {
        ...this.state.broacastFilterObject,
        visible: false,
        selectedType: this.state.broacastFilterObject.type,
      },
    });
    this.fetchRecentActivityItems();
  };

  handleChangeFilter = (key) => (e) => {
    if (e) e.preventDefault();
    this.setState({
      broacastFilterObject: {
        ...this.state.broacastFilterObject,
        type: e.target.value,
      },
    });
  };

  render() {
    const { broacastFilterObject } = this.state;
    const sortedBroadcastByName = this.BROADCAST_TYPE.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
    const { currentUser } = this.props;

    return (
      <View style={{ flex: 1 }}>
        <FormModal
          title={<span>Filter</span>}
          visible={broacastFilterObject.visible}
          onClose={this.closeFilter}
          onSubmit={this.submitFilter}
        >
          <div className="form-group">
            <label>Jenis Notifikasi</label>
            <select
              className="form-control"
              value={broacastFilterObject.type}
              onChange={this.handleChangeFilter()}
            >
              <option value="">Tampilkan Semua</option>
              {sortedBroadcastByName.sort().map((b) => {
                let display = "block";
                if (
                  b.type === "EmployeeAttendanceRecord" ||
                  b.type === "EmployeeSavingTransaction"
                ) {
                  if (
                    currentUser &&
                    currentUser.employeeIds &&
                    currentUser.employeeIds.length > 0
                  ) {
                    display = "block";
                  } else {
                    display = "none";
                  }
                }

                if (
                  b.type !== "EmployeeAttendanceRecord" &&
                  b.type !== "EmployeeSavingTransaction"
                ) {
                  if (
                    b.type === "Article" ||
                    b.type === "BroadcastMessage" ||
                    b.type === "SchoolAgenda"
                  ) {
                    display = "block";
                  } else {
                    if (
                      (currentUser &&
                        currentUser.parentIds &&
                        currentUser.parentIds.length > 0) ||
                      (currentUser &&
                        currentUser.studentIds &&
                        currentUser.studentIds.length > 0)
                    ) {
                      display = "block";
                    } else {
                      display = "none";
                    }
                  }
                }

                return (
                  <option
                    value={b.type}
                    style={{
                      display,
                    }}
                  >
                    {b.name}
                  </option>
                );
              })}
            </select>
          </div>
        </FormModal>

        <BackgroundImage opacity={0.8} />
        <StatusBar
          backgroundColor="#1696ff"
          barStyle="light-content"
          animated={true}
        />
        <div className="fixed-top">
          <HeaderBar
            title={<Text>School Talk</Text>}
            right={
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                }}
              >
                <TouchableOpacity onPress={this.openFilter}>
                  <Text
                    style={{
                      paddingRight: 12,
                      paddingLeft: 20,
                      color: "white",
                      fontSize: 16,
                      paddingVertical: 2,
                    }}
                  >
                    <i
                      className="fa fa-filter"
                      style={{
                        fontSize: 20,
                        marginBottom: 5,
                      }}
                    />
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={this.refresh}>
                  <Text
                    style={{
                      paddingRight: 12,
                      paddingLeft: 20,
                      color: "white",
                      fontSize: 16,
                      paddingVertical: 2,
                    }}
                  >
                    <i
                      className="fa fa-sync-alt"
                      style={{
                        fontSize: 20,
                        marginBottom: 5,
                      }}
                    />
                  </Text>
                </TouchableOpacity>
              </View>
            }
          />
        </div>
        <View style={{ zIndex: 1, flex: 1, marginTop: 55, marginBottom: 55 }}>
          {this.state.isLoading ? (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                style={{
                  width: 240,
                  height: 240,
                  resizeMode: "contain",
                  opacity: 0.8,
                }}
                source={require("../assets/business-advertising-promote.png")}
              />
              <Text
                style={{
                  fontSize: 17,
                  color: "#737373",
                  textAlign: "center",
                  paddingHorizontal: 30,
                  marginBottom: 30,
                }}
              >
                <i className="fa fa-spinner fa-spin" /> Sedang mengambil
                aktivitas terbaru Anda...
              </Text>
            </View>
          ) : this.state.recentActivityItems.length === 0 ? (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                style={{
                  width: 240,
                  height: 240,
                  resizeMode: "contain",
                  opacity: 0.8,
                }}
                source={require("../assets/business-advertising-promote.png")}
              />
              <Text
                style={{
                  fontSize: 17,
                  color: "#737373",
                  textAlign: "center",
                  paddingHorizontal: 30,
                  marginBottom: 30,
                }}
              >
                Tidak ada aktivitas untuk Anda saat ini. Update terus aplikasi
                School Talk, jangan sampai ketinggalan!
              </Text>
              <TouchableOpacity onPress={this.refresh} activeOpacity={0.6}>
                <View
                  style={{
                    backgroundColor: "white",
                    shadowColor: "#000",
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.2,
                    shadowRadius: 4,
                    elevation: 1,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 17,
                      color: "#737373",
                      textAlign: "center",
                      paddingHorizontal: 30,
                      paddingVertical: 10,
                    }}
                  >
                    <i className="fa fa-history" /> Refresh
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          ) : (
            <InfiniteScroll
              dataLength={this.state.recentActivityItems.length} //This is important field to render the next data
              next={this.fetchMoreData}
              hasMore={this.state.hasMore}
              loader={
                <h4 className="text-center my-4 text-info">
                  <i className="fa fa-circle-notch fa-spin" /> Loading
                </h4>
              }
              endMessage={
                <h4 className="text-center my-4 text-secondary">
                  <i className="fa fa-info-circle" /> No more activites at the
                  moment.
                </h4>
              }
              // onScroll={this.listenScrollToTop}
              // below props only if you need pull down functionality
              refreshFunction={this.refresh}
              pullDownToRefresh
              pullDownToRefreshContent={
                <h4 className="text-center my-4 text-info">
                  {this.state.recentActivityItems.length === 0 ? (
                    <span>&nbsp;</span>
                  ) : (
                    <span>&#8595; Pull down to refresh</span>
                  )}
                </h4>
              }
              pullDownToRefreshThreshold={200}
              releaseToRefreshContent={
                <h4 className="text-center my-4 text-info">
                  &#8593; Release to refresh
                </h4>
              }
            >
              {this.state.recentActivityItems.map(this.renderActivityItem)}
            </InfiniteScroll>
          )}
        </View>

        <NavigationWithFlex
          history={this.props.history}
          session={this.state.session}
        />
      </View>
    );
  }
}

const QUERY = gql`
  query currentUser {
    currentUser {
      _id
      name
      email
      phone
      studentIds
      parentIds
      employeeIds
      prefixes
      roles
    }
  }
`;

const RECENT = gql`
  query recentActivityItemsBySessionId(
    $sessionId: String!
    $timeOffset: Float
    $limit: Int
    $type: String
  ) {
    recentActivityItemsBySessionId(
      sessionId: $sessionId
      timeOffset: $timeOffset
      limit: $limit
      type: $type
    ) {
      _id
      type
      Article {
        _id
        title
        content
        markArticleAs
        thumbnail
        author
      }
      Student {
        _id
        regNumber
        name
        PREFIX
      }
      StudentSavingTransaction {
        _id
        type
        transactionNumber
        description
        amount
        PREFIX
      }
      Employee {
        _id
        regNumber
        name
        PREFIX
      }
      EmployeeSavingTransaction {
        _id
        type
        transactionNumber
        description
        amount
        PREFIX
      }
      SchoolAgenda {
        _id
        title
        description
        startDateTime
        endDateTime
        isHoliday
        PREFIX
      }
      StudentBillPayment {
        _id
        transactionNumber
        date
        paymentMethodName
        total
        details {
          _id
          month
          academicYear
          studentBillName
          amount
          PREFIX
        }
        officerName
        PREFIX
      }
      StudentAttendanceRecord {
        _id
        scheduleName
        date
        month
        year
        day
        checkInStatus
        checkInMode
        checkInMinutes
        checkOutStatus
        checkOutMode
        checkOutMinutes
        isActiveDay
        PREFIX
      }
      EmployeeAttendanceRecord {
        _id
        scheduleName
        date
        month
        year
        day
        checkInStatus
        checkInMode
        checkInMinutes
        checkOutStatus
        checkOutMode
        checkOutMinutes
        isActiveDay
        PREFIX
      }
      BroadcastMessage {
        _id
        title
        content
        attachedAudios
        attachedDocuments
        attachedImages
        authorName
        _createdAt
        PREFIX
      }
      AcademicScore {
        _id
        academicYear
        semester
        subjectName
        subjectId
        academicAssessmentId
        score
        PREFIX
      }
      PrimaryExtracurricularScore {
        _id
        academicYear
        semester
        extracurricularName
        extracurricularId
        type
        score
        predicateScore
        descriptiveScore
        PREFIX
      }
      StudentEventNote {
        _id
        academicYear
        semester
        attachedImageUrl
        date
        note
        PREFIX
      }
      AspectScore {
        _id
        academicYear
        date
        month
        year
        aspectName
        aspectGroupName
        status
        PREFIX
      }
      SchoolViolation {
        _id
        name
        score
        level
        description
        violationCategory
        _createdAt
        _updatedAt
        PREFIX
      }
      StudentViolation {
        _id
        academicYear
        semester
        studentId
        schoolViolationId
        followUp
        description
        date
        _createdAt
        _updatedAt
        PREFIX
      }
      Classwork {
        _id
        subjectId
        teacherId
        teacherName
        title
        subjectName
        academicYear
        semester
        classroomId
        description
        type
        _createdAt
        _updatedAt
        PREFIX
      }
      ClassworkExtra {
        _id
        extracurricularId
        classroomId
        teacherId
        teacherName
        title
        extracurricularName
        academicYear
        semester
        description
        type
        attachmentFileUrl
        _createdAt
        _updatedAt
        PREFIX
      }
      SchoolPayBills {
        total
        status
        transactionNumber
        details {
          _id
          month
          academicYear
          studentBillId
          studentBillName
          amount
        }
        PREFIX
        _createdAt
        _updatedAt
      }
      SubjectVideoMeeting {
        _id
        semester
        academicYear
        topic
        password
        agenda

        classroomId
        classroomName

        subjectId
        subjectName

        teacherName

        joinUrl
        PREFIX
        _createdAt
        _updatedAt
      }

      BookBorrowingTransaction {
        _id
        academicYear
        studentId
        studentName

        employeeId
        employeeName
        bookId
        bookName

        borrowDate
        dueDate
        _createdAt
        _updatedAt
      }

      BookReturned {
        _id
        studentId
        studentName

        employeeId
        employeeName
        bookId
        bookName
        returnDate
        totalFines
        _createdAt
        _updatedAt
      }

      BookReturnedEmployee {
        _id
        studentId
        studentName

        employeeId
        employeeName
        bookId
        bookName
        returnDate
        totalFines
        _createdAt
        _updatedAt
      }
      BookBorrowingEmployeeTransaction {
        _id
        academicYear
        studentId
        studentName

        employeeId
        employeeName
        bookId
        bookName

        borrowDate
        dueDate
        _createdAt
        _updatedAt
      }

      CommBook {
        _id
        studentId
        classroomId

        employeeId
        employeeName

        studentName
        date
        PREFIX
      }

      SchoolAppreciation {
        _id
        name
        score
        description
        behaviourCategory
        _createdAt
        _updatedAt
        PREFIX
      }
      StudentAppreciation {
        _id
        academicYear
        semester
        studentId
        goodBehaviorId
        description
        date
        _createdAt
        _updatedAt
        PREFIX
      }

      _createdAt
      _updatedAt
    }
  }
`;

// const NEWEST = gql`
//   query newestActivityItemBySessionId($sessionId: String!) {
//     newestActivityItemBySessionId(sessionId: $sessionId) {
//       _id
//     }
//   }
// `;

const INTERACT = gql`
  mutation interactWithActivityItem(
    $activityItemId: String!
    $type: String!
    $sessionId: String!
  ) {
    interactWithActivityItem(
      activityItemId: $activityItemId
      type: $type
      sessionId: $sessionId
    )
  }
`;

const UPDATE_STUDENT_ATTENDANCE_ZOOM = gql`
  mutation updateStudentAttendanceByZoom(
    $meetingId: String!
    $studentId: String!
    $status: String!
  ) {
    updateStudentAttendanceByZoom(
      meetingId: $meetingId
      studentId: $studentId
      status: $status
    )
  }
`;

export default (props) => (
  <ApolloConsumer>
    {(client) => (
      <Mutation mutation={UPDATE_STUDENT_ATTENDANCE_ZOOM}>
        {(updateStudentAttendanceByZoom) => (
          <Mutation mutation={INTERACT}>
            {(interactWithActivityItem) => (
              <Query query={QUERY}>
                {({ error, loading, data }) => (
                  <Activity
                    {...props}
                    client={client}
                    interactWithActivityItem={interactWithActivityItem}
                    updateStudentAttendanceByZoom={
                      updateStudentAttendanceByZoom
                    }
                    currentUser={
                      data && data.currentUser
                        ? data.currentUser
                        : {
                            studentIds: [],
                            parentIds: [],
                            employeeIds: [],
                          }
                    }
                  />
                )}
              </Query>
            )}
          </Mutation>
        )}
      </Mutation>
    )}
  </ApolloConsumer>
);
