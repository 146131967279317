import React, { Component } from "react";
import { View, TouchableOpacity, Image, Text } from "react-native";
import dayjs from "dayjs";

class StudentAppreciationPost extends Component {
  handleClick = () => {
    if (this.props.onPress) {
      const {
        _id,
        Student,
        StudentAppreciation,
        SchoolAppreciation,
        _createdAt
      } = this.props;
      this.props.onPress({
        _id,
        type: "ACTIVITY_ITEM_PRESSED",
        activityType: "StudentAppreciation",
        Student,
        StudentAppreciation,
        SchoolAppreciation,
        _createdAt
      });
    }
  };

  render() {
    const {
      Student,
      // StudentAppreciation,
      SchoolAppreciation,
      _createdAt
    } = this.props;

    console.log(this.props)
    // return null;

    return (
      <View
        style={{
          paddingVertical: 7
        }}
      >
        <TouchableOpacity onClick={this.handleClick} activeOpacity={0.6}>
          <View
            style={{
              paddingBottom: 4,
              backgroundColor: "white",
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.5,
              shadowRadius: 2,
              elevation: 2
            }}
          >
            <View
              style={{
                paddingVertical: 8,
                paddingHorizontal: 12
              }}
            >
              <Text
                style={{
                  fontSize: 13,
                  fontWeight: "bold",
                  color: "#59eb4b",
                  marginTop: 2
                }}
              >
                <i className="fa fa-info-circle" /> Update Apresiasi Siswa
              </Text>
              <Text style={{ fontSize: 12, color: "#59eb4b", marginTop: 2 }}>
                {dayjs(_createdAt).format("dddd, DD MMMM YYYY   HH:mm")}
              </Text>
              <Text style={{ fontSize: 12, color: "red", marginTop: 2 }}>
                {this.props._id}
              </Text>

              <View
                style={{
                  flexDirection: "row",
                  marginVertical: 4,
                  borderColor: "#ddd",
                  borderWidth: 2,
                  borderRadius: 4
                }}
              >
                <View
                  style={{
                    margin: 4,
                    padding: 0,
                    borderRadius: 3,
                    justifyContent: "center"
                  }}
                >
                  <Image
                    source={require("../../assets/librarian-female-caucasian.png")}
                    style={{
                      width: 100,
                      height: 120
                    }}
                    resizeMode="cover"
                  />
                </View>
                <View
                  style={{
                    flex: 1,
                    marginTop: 4,
                    marginBottom: 8,
                    marginLeft: 6
                  }}
                >
                  <Text
                    style={{
                      marginTop: 6,
                      fontSize: 17,
                      fontWeight: "bold"
                    }}
                  >
                    {SchoolAppreciation ? SchoolAppreciation.name : "-"}
                  </Text>
                  <Text
                    style={{
                      marginBottom: 6,
                      fontSize: 13
                    }}
                  >
                    {SchoolAppreciation ? SchoolAppreciation.academicYear : "-"}
                  </Text>
                  <Text
                    style={{
                      marginBottom: 4,
                      fontSize: 15,
                      fontWeight: "bold"
                    }}
                  >
                    <i className="fa fa-user-graduate" /> {Student ? Student.name : "-"}
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Text>
                      <i className="fa fa-info-circle" /> Poin:
                    </Text>
                    <Text
                      style={{
                        fontSize: 24,
                        marginLeft: 8,
                        marginVertical: 6,
                        paddingVertical: 3,
                        paddingHorizontal: 8,
                        borderWidth: 2,
                        borderColor: "green",
                        borderRadius: 4,
                        color: "#59eb4b"
                      }}
                    >
                      {SchoolAppreciation ? SchoolAppreciation.score : "-"}
                    </Text>
                  </View>
                  {/* <Text style={{ marginTop: 4, color: "#59eb4b" }}>
                    Klik untuk melihat tindak lanjut
                  </Text> */}
                </View>
                <View
                  style={{
                    marginRight: 20,
                    marginTop: -60,
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Text style={{ fontSize: 36, color: "#59eb4b" }}>
                    <i className="fa fa-info-circle" />
                  </Text>
                  {/* <div
                    style={{
                      borderTop: "60px solid red",
                      borderBottom: "60px solid transparent",
                      borderLeft: "60px solid transparent"
                    }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        top: 42,
                        width: 60,
                        left: 12,
                        textAlign: "center",
                        transform: "rotate(45deg)",
                        display: "block",
                        color: "white"
                      }}
                    >
                      <b>Nilai</b>
                    </span>
                  </div> */}
                </View>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default StudentAppreciationPost;
